import React from 'react';
import type SwiperCore from 'swiper';
import { ArrowLeftIcon, ArrowRightIcon } from '@ui-kit/icons';
import styles from './ResponsiveCarousel.module.css';

interface ISwiperButtonProps {
	swiperRef: React.RefObject<SwiperCore>;
	direction: 'prev' | 'next';
}

const SwiperButton: React.FC<ISwiperButtonProps> = ({ swiperRef, direction }) => {
	const handleClick = () => {
		if (swiperRef.current) {
			if (direction === 'prev') {
				swiperRef.current.slidePrev();
			} else {
				swiperRef.current.slideNext();
			}
		}
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div className={styles.arrow} onClick={handleClick}>
			{direction === 'prev' ? (
				<ArrowLeftIcon fill={'#3F4647'} height={24} width={24} />
			) : (
				<ArrowRightIcon fill={'#3F4647'} height={24} width={24} />
			)}
		</div>
	);
};

export default SwiperButton;
