import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { HOME_SECTIONS } from '@ui-modules/home/utils';
import { CONTENT_TOP_OFFSET, HEADER_HEIGHT } from '@constants';

const VALID_SECTIONS = HOME_SECTIONS.map((item) => item.id);

export const useScrollRouter = () => {
	const [activeSection, setActiveSection] = useState('');
	const location = useLocation();

	const updateUrlHash = useCallback((route: string) => {
		if (VALID_SECTIONS.includes(route)) {
			const hash = route ? `#${route}` : '';
			const newUrl = `${window.location.pathname}${route !== '/' ? hash : ''}`;
			window.history.replaceState(null, '', newUrl);
		}
	}, []);

	const handleScroll = useCallback(() => {
		let currentlyVisible: Array<{ id: string; ratio: number }> = [];

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const sectionId = entry.target.id;

					if (VALID_SECTIONS.includes(sectionId)) {
						if (entry.isIntersecting) {
							currentlyVisible.push({
								id: sectionId,
								ratio: entry.intersectionRatio,
							});
						} else {
							currentlyVisible = currentlyVisible.filter((item) => item.id !== sectionId);
						}
					}
				});

				// Find the section with the highest visibility ratio
				if (currentlyVisible.length > 0) {
					const mostVisible = currentlyVisible.reduce((prev, current) => (current.ratio > prev.ratio ? current : prev));

					setActiveSection(mostVisible.id);
					updateUrlHash(mostVisible.id);
				}
			},
			{
				rootMargin: `${-HEADER_HEIGHT - CONTENT_TOP_OFFSET - 10}px 0px -90% 0px`,
				threshold: [0, 0.25, 0.5, 0.75, 1.0],
			},
		);

		// Only observe our specific sections
		VALID_SECTIONS.forEach((sectionId) => {
			const element = document.getElementById(sectionId);
			if (element) {
				observer.observe(element);
			}
		});

		return () => observer.disconnect();
	}, [updateUrlHash]);

	// Set up IntersectionObserver
	useEffect(() => {
		const cleanup = handleScroll();
		return cleanup;
	}, [handleScroll]);

	// Handle initial hash and smooth scroll with offset adjustment
	useEffect(() => {
		const scrollToHash = () => {
			const hash = location.hash.replace('#', '');
			if (hash && VALID_SECTIONS.includes(hash)) {
				const element = document.getElementById(hash);

				if (element) {
					const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

					// Calculate the top offset including HEADER_HEIGHT and CONTENT_TOP_OFFSET
					const offset = HEADER_HEIGHT + CONTENT_TOP_OFFSET;
					const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
					const offsetPosition = elementPosition - offset;

					// Use requestAnimationFrame to ensure DOM is fully rendered
					requestAnimationFrame(() => {
						window.scrollTo({
							top: offsetPosition,
							behavior: prefersReducedMotion ? 'auto' : 'smooth',
						});
					});

					setActiveSection(hash);
				}
			}
		};

		// Add a delay to handle cases where elements might not be ready
		const timeout = setTimeout(scrollToHash, 100);

		return () => clearTimeout(timeout);
	}, [location.hash]);

	return { activeSection };
};
