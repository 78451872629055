import { Link } from '@ui-kit';
import { ROUTES } from '@constants';
import styles from '../TabBar.module.css';
import logo from '../../../assets/lookify-logo.svg';

const Logo = () => {
	return (
		<Link
			to={ROUTES.home()}
			onClick={() => {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}}
		>
			<img alt="logo" className={styles.header__logo} height={40} src={logo as string} width={170} />
		</Link>
	);
};

export default Logo;
