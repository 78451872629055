export const PUBLIC_AUTH_ROUTES = {
	/** Basic login page. */
};

export const ROUTES = {
	...PUBLIC_AUTH_ROUTES,
	// Auth pages.
	notFoundPage: () => '*',
	home: () => '/',
	inspiration: () => '#inspiration',
	features: () => '#features',
	reviews: () => '#reviews',
	howItWorks: () => '#how-it-works',
	joinUs: () => '#join-us',
	whoWeAre: () => '#who-we-are',
	faq: () => '#faq',
	// @TODO UNHIDE V2.0.0
	// demo: () => 'demo',
	// wardrobe: () => '/wardrobe',
};
