import { configureStore, combineReducers, bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import type { Config as StateSyncConfig } from 'redux-state-sync';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { EventEmitter } from './EventEmitter';
import type { TypedUseSelectorHook } from 'react-redux';
import type { Middleware, Reducer, ActionCreator, ActionCreatorsMapObject } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';

/** Abstract implementation of Redux Store includes persist & devtools feature. */
export abstract class AbstractReduxService<
	TRootState,
	TActions extends Record<string, unknown>,
> extends EventEmitter<TActions> {
	protected constructor(rootReducer: Reducer<TRootState>, stateSyncConfig: StateSyncConfig) {
		super();

		const persistActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

		this.store = configureStore({
			reducer: rootReducer,
			middleware: (getDefaultMiddleware) =>
				getDefaultMiddleware({
					serializableCheck: {
						ignoredActions: [...persistActions],
					},
				}).concat([this.eventEmitterMiddleware, createStateSyncMiddleware(stateSyncConfig)]),
		});
		initStateWithPrevTab(this.store);
	}

	readonly store;

	get persistor() {
		return persistStore(this.store);
	}

	static persistReducer = persistReducer;
	static combineReducers = combineReducers;
	bindActionCreators = bindActionCreators;

	useAppDispatch = () => useDispatch<AbstractReduxService<TRootState, TActions>['store']['dispatch']>();
	useAppSelector: TypedUseSelectorHook<ReturnType<AbstractReduxService<TRootState, TActions>['store']['getState']>> =
		useSelector;
	useBindActionCreators: IUseBindActionCreator = (actionCreator: Parameters<IUseBindActionCreator>[0]) =>
		bindActionCreators(actionCreator, this.store.dispatch);

	eventEmitterMiddleware: Middleware<unknown, TRootState> = (store) => (next) => (action) => {
		const result = next(action);
		this.emit(action.type, action.payload);
		return result;
	};
}

interface IUseBindActionCreator {
	<A, C extends ActionCreator<A>>(actionCreator: C): C;
	<A extends ActionCreator<any>, B extends ActionCreator<any>>(actionCreator: A): B;
	<A, M extends ActionCreatorsMapObject<A>>(actionCreators: M): M;
	<M extends ActionCreatorsMapObject<any>, N extends ActionCreatorsMapObject<any>>(actionCreators: M): N;
}

// Mapped Type helpers.
export type TFullActionName<TActionName extends string, TReducerName extends string> = `${TReducerName}/${TActionName}`;
export type TReducerActions<
	TActions extends Record<string, (state: never, action: Action) => any>,
	TReducerName extends string,
> = {
	[Key in keyof TActions as TFullActionName<Key extends string ? Key : never, TReducerName>]: Parameters<
		TActions[Key]
	>[0];
};
