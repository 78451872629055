import styles from './InspirationCard.module.css';
import type { ReactNode } from 'react';

const InspirationCard = ({ icon, title, subtitle }: IInspirationCardProps) => {
	return (
		<div className={styles.inspiration__feature}>
			<div className={styles.inspiration__featureIcon}>{icon}️</div>
			<div className={styles.inspiration__featureInfoWrapper}>
				<h3 className={styles.inspiration__featureTitle}>{title}</h3>
				{!!subtitle && <p className={styles.inspiration__featureDescription}>{subtitle}</p>}
			</div>
		</div>
	);
};

export interface IInspirationCardProps {
	icon: ReactNode;
	title: string;
	subtitle?: string;
}

export default InspirationCard;
