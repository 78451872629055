import styles from './HomePage.module.css';
import { Page } from '../../components';
import { useScrollRouter } from '@hooks';
import { HOME_SECTIONS } from '@ui-modules/home/utils';
import { useSectionAnalytics } from '../../../common/hooks/useSectionAnalytics';
import { t } from 'i18next';
import { APP_DESCRIPTION } from '@constants';

const HomePage = () => {
	const { activeSection } = useScrollRouter();

	useSectionAnalytics(activeSection);

	return (
		<Page description={APP_DESCRIPTION} title="Home Page">
			<div className={styles.homePage__container}>
				{HOME_SECTIONS.map(({ id, Component }) => (
					<div id={id} key={id}>
						<Component />
					</div>
				))}
			</div>
		</Page>
	);
};

export default HomePage;
