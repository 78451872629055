// dependencies
import { ROUTES } from '@constants';
import { createBrowserRouter } from 'react-router-dom';
import { SecureRoutesWrapper } from './components/SecureRoutesWrapper';

import { HomePage, NotFoundPage } from './pages';

import { AppRouterContextProvider } from './AppRouterContext';
import { MainShell } from './shells/MainShell';
// @TODO UNHIDE V2.0.0
// import { DemoPage } from './pages/DemoPage';
// import { WardrobePage } from './pages/WardrobePage';

const signingInFallbackElement = <>LOADING...</>;

export const appRouter = createBrowserRouter([
	{
		element: (
			<SecureRoutesWrapper signingInFallback={signingInFallbackElement}>
				{/*<InitializeUserWrapper>*/}
				{/*	<InitializeSessionWrapper>*/}
				<AppRouterContextProvider getAppRouter={() => appRouter}>
					<MainShell />
				</AppRouterContextProvider>
				{/*</InitializeSessionWrapper>*/}
				{/*</InitializeUserWrapper>*/}
			</SecureRoutesWrapper>
		),
		children: [
			{
				path: ROUTES.notFoundPage(),
				element: <NotFoundPage />,
			},
			{
				path: ROUTES.home(),
				element: <HomePage />,
			},
			// @TODO UNHIDE V2.0.0
			// {
			// 	path: ROUTES.demo(),
			// 	element: <DemoPage />,
			// },
			// {
			// 	path: ROUTES.wardrobe(),
			// 	element: <WardrobePage />,
			// },
		],
	},
]);
