import { StateInspector } from 'reinspect';
import AppContextsProvider from './AppContextsProvider';
import { appRouter } from './appRouter';
import './styles';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ServicesContainer } from '../services/setup/ServicesContainer';
import { NotFoundPage } from './pages';

function App() {
	const [servicesContainer] = useState(() => new ServicesContainer());

	useEffect(() => {
		servicesContainer.resolve('SentryService').initialize();
	}, []);

	return (
		<>
			<AppContextsProvider servicesContainer={servicesContainer}>
				<StateInspector name="App">
					<RouterProvider fallbackElement={<NotFoundPage />} router={appRouter} />
				</StateInspector>
			</AppContextsProvider>
			{/** @todo fix the ReactQueryDevtools here [@shant issa] */}
			{/*<ReactQueryDevtools initialIsOpen={false} />*/}
		</>
	);
}

export default App;
