import { clsx } from '@utils';
import styles from './Toast.module.css';
import { CloseIcon, ErrorAlertIcon, InfoAlertIcon, SuccessAlertIcon, WarningAlertIcon } from '@ui-kit/icons';
import type { KeyboardEventHandler, MouseEventHandler, ReactNode } from 'react';

const Toast = ({
	type,
	title,
	subtitle,
	shouldShowClose,
	linksDisabled,
	onPress,
	onClose,
	className,
	fullWidth,
	ContentComponent,
}: IToastProps) => {
	const iconComponent = typeIconMap[type];

	const handlePress: MouseEventHandler & KeyboardEventHandler = () => {
		onPress?.();
		onClose?.();
	};
	const handleClose: MouseEventHandler & KeyboardEventHandler = (event) => {
		event.stopPropagation();
		onClose?.();
	};

	return (
		<div
			className={clsx(
				styles.toast,
				!!onPress && styles.toast_clickable,
				fullWidth && styles.toast_fullWidth,
				className,
			)}
			role={onPress ? 'link' : 'none'}
			tabIndex={onPress ? 0 : undefined}
			onClick={handlePress}
			onKeyPress={handlePress}
		>
			<div className={styles.toast__wrapper} role="alert">
				<div className={styles.toast__header}>{iconComponent}</div>
				<div className={styles.toast__body}>
					<div className={styles.toast__title} data-type={type}>
						<h4>{title}</h4>
					</div>
					{subtitle ? (
						<div className={styles.toast__subtitle}>
							<small className={styles.toast__subtitleText}>{subtitle}</small>
						</div>
					) : null}
					{ContentComponent && <div>{ContentComponent}</div>}
				</div>
			</div>
			{shouldShowClose ? (
				<span
					aria-label="close"
					className={styles.toast__closeIcon}
					role="button"
					tabIndex={-1}
					onClick={handleClose}
					onKeyPress={handleClose}
				>
					<CloseIcon height={20} width={20} />
				</span>
			) : null}
		</div>
	);
};

const typeIconMap: Record<TToastType, ReactNode> = {
	error: <ErrorAlertIcon aria-label="error" height={38} role="img" width={38} />,
	info: <InfoAlertIcon aria-label="info" height={38} role="img" width={38} />,
	warning: <WarningAlertIcon aria-label="warning" height={38} role="img" width={38} />,
	success: <SuccessAlertIcon aria-label="success" height={38} role="img" width={38} />,
};

export type TToastType = 'success' | 'info' | 'error' | 'warning';
export interface IToastProps {
	/** Appearance of a toast. */
	type: TToastType;
	/** Bold title of a message. */
	title: string;
	/** Optional description of a message. */
	subtitle?: string;
	/** Shows close button and nvokes when the close button pressed. */
	shouldShowClose?: boolean;
	/** Invokes when toast pressed. */
	onPress?: () => void;
	/** Invokes when pressed OR the close button pressed. */
	onClose?: () => void;
	/** Class name of the Toast component */
	className?: string;
	/** Set toast width to 100% */
	fullWidth?: boolean;
	/** Disable links in subtitle. Default: false */
	linksDisabled?: boolean;
	/** React/HTML component (likely svg). */
	ContentComponent?: ReactNode;
}

export default Toast;
