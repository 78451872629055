import { clsx } from '@utils';
import styles from './Accordion.module.css';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { ArrowUpRoundedIcon } from '@ui-kit';

const Accordion = ({ title, children }: IAccordionProps) => {
	const [open, setOpen] = useState(false);

	return (
		<div>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
			<div className={styles.accordion__wrapper} data-open={open} onClick={() => setOpen(!open)}>
				<p>{title}</p>
				<div
					className={clsx(styles.arrowIcon, {
						[styles.arrowIconOpen]: open,
					})}
				>
					<ArrowUpRoundedIcon fill={open ? 'var(--color-primary-50)' : 'var(--color-secondary-gray-200)'} />
				</div>
			</div>
			<div
				className={clsx(
					styles.children__wrapper,
					open ? styles.children__wrapper_open : styles.children__wrapper_closed,
				)}
			>
				{children}
			</div>
		</div>
	);
};

export interface IAccordionProps {
	title: string;
	children: ReactNode;
}

export default Accordion;
