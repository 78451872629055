import { ErrorBoundary } from '../ErrorBoundary';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '@constants';
import { useEffect } from '@hooks';
import type { ReactNode } from 'react';

/** Top-level wrapper for page components. */
const Page = ({ children, title, description, image, scrollDisabled }: IPageProps) => {
	useEffect(() => {
		if (scrollDisabled) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
		// Cleanup on unmount
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [scrollDisabled]);

	return (
		<>
			<Helmet defaultTitle={APP_TITLE} titleTemplate={`%s | ${APP_TITLE}`}>
				<title>{title}</title>
				<meta content={description || `${title} page of ${APP_TITLE}`} name="description" />
				<meta content={title} property="og:title" />
				<meta content={description || `${title} page of ${APP_TITLE}`} property="og:description" />
				<meta content="website" property="og:type" />
				<meta content={image || '/assets/favicons/ms-icon-310x310.png'} property="og:image" />
			</Helmet>

			<ErrorBoundary>{children}</ErrorBoundary>
		</>
	);
};

export interface IPageProps {
	children: ReactNode;
	title: string;
	description?: string;
	image?: string;
	scrollDisabled?: boolean;
}

export default Page;
