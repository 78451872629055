import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import styles from './MainShell.module.css';

import { Suspense } from 'react';
import { TabBar, Footer } from '../../components';
import { TabBarContextProvider } from '../../components/TabBar';

const MainShell = () => {
	return (
		<ErrorBoundary>
			<TabBarContextProvider>
				<TabBar />
				<div className={styles.mainShell}>
					<Suspense fallback={<>LOADING...</>}>
						<Outlet />
					</Suspense>
				</div>
				<Footer />
			</TabBarContextProvider>
		</ErrorBoundary>
	);
};

export default MainShell;
