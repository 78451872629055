import { useScreenSize, useTranslation } from '@hooks';
import styles from './Reviews.module.css';
import { BayLeavesLeftIcon, BayLeavesRightIcon, HangersPrototypeIcon } from '@ui-kit';
import ReviewCard from '@ui-modules/home/components/ReviewCard';
import { testimonials as getTestimonials } from '@ui-modules/home/utils';

import ResponsiveCarousel from '../../../../ui-kit/components/ResponsiveCarousel';

const Reviews = () => {
	const { t } = useTranslation();

	const testimonials = getTestimonials(t);
	const { width } = useScreenSize();

	return (
		<section className={styles.reviews__container}>
			{width > 1135 && (
				<HangersPrototypeIcon className={styles.reviews__hangersLeftIcon} fill="var(--color-secondary-gray-900)" />
			)}
			<div>
				<div className={styles.reviews__titleWrapper}>
					<div className={styles.reviews__titleDescriptionWrapper}>
						<h2 className={styles.reviews__title}>
							<BayLeavesLeftIcon className={styles.reviews__bayLeavesIconLeft} fill="#3F4647" />
							<span className={styles.reviews__title_trustedHighlight}>{t('Trusted by')}</span> {t('style enthusiasts')}{' '}
							<span className={styles.reviews__title_everywhereHighlight}>{t('everywhere')}</span>
							<BayLeavesRightIcon className={styles.reviews__bayLeavesIconRight} fill="#3F4647" />
						</h2>
						<p className={styles.reviews__description}>
							{t(
								'Discover how Lookify’s vision and dedicated team have created meaningful experiences. Hear directly from those who’ve collaborated with us and seen the impact firsthand.',
							)}
						</p>
					</div>
				</div>
			</div>
			<div className={styles.reviews__responsiveCarouselWrapper}>
				<ResponsiveCarousel
					data={testimonials}
					renderItem={(item, index, activeIndex) => <ReviewCard activeIndex={activeIndex} index={index} {...item} />}
				/>
			</div>
			{width > 1135 && (
				<HangersPrototypeIcon className={styles.reviews__hangersRightIcon} fill="var(--color-secondary-gray-900)" />
			)}
		</section>
	);
};

export default Reviews;
