import type { TFunction } from 'i18next';
import { ROUTES } from '@constants';
export const navItems = (t: TFunction): TNavItems[] => [
	{ title: t('Who We Are'), hash: ROUTES.whoWeAre() },
	{ title: t('Inspiration'), hash: ROUTES.inspiration() },
	{ title: t('Features'), hash: ROUTES.features() },
	{ title: t('Reviews'), hash: ROUTES.reviews() },
	{ title: t('How It Works'), hash: ROUTES.howItWorks() },
	{ title: t('Join Us'), hash: ROUTES.joinUs() },
	{ title: t('FAQ'), hash: ROUTES.faq() },
];
//

export type TNavItems = { title: string; hash: string };
