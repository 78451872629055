import type { IAxiosService } from '../interfaces/AxiosService.interface';
import type { ImageType } from 'react-images-uploading';

export class MediaService {
	static inject = ['AxiosService', 'AnalyticsService'] as const;
	constructor(private readonly axios: IAxiosService) {}

	async removeBackground(images: ImageType[]) {
		const formData = new FormData();
		images.forEach((image) => formData.append('images', image.file as Blob));

		try {
			const response: { imageLinks: string[] } = await this.axios.post('/remove-background', formData);
			return response.imageLinks;
		} catch (error) {
			console.error('Error removing background:', error);
			throw error;
		}
	}
}
