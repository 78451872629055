import { useScreenSize, useTranslation } from '@hooks';
import styles from './HowItWorks.module.css';
import { DoodlesIcon, HangersPrototypeIcon } from '@ui-kit';
import HowItWorksCard from '@ui-modules/home/components/HowItWorksCard';

const HowItWorks = () => {
	const { t } = useTranslation();
	const { width } = useScreenSize();

	return (
		<section className={styles.container}>
			<div className={styles.sectionsContainer}>
				<div className={styles.sectionsWrapper}>
					<div>
						<div className={styles.titleWrapper}>
							<div>
								<h2 className={styles.title}>
									{t('How Lookify')} <span className={styles.title_highlighted}>{t('works')}</span>
								</h2>
								{width > 1300 && <DoodlesIcon className={styles.doodlesIcon} />}
							</div>
							<p className={styles.description}>
								{t(
									'Unlock the full potential of your wardrobe with Lookify. Our AI-powered platform simplifies your style journey, from organizing your clothes to discovering new outfit combinations.',
								)}
							</p>
						</div>
						{width > 1024 && (
							<div className={styles.embarkStylesWrapper}>
								<img
									alt="features"
									className={styles.embarkImageWrapper}
									src="/assets/images/how_it_works_section.jpeg"
								/>
								<h3 className={styles.embarkAdventureTitle}>
									{t('Start on your')} <span className={styles.embarkAdventureTitle_redHighlight}>{t('style')}</span>{' '}
									<span className={styles.embarkAdventureTitle_fleshHighlight}>{t('adventure')}</span>
								</h3>

								<HangersPrototypeIcon className={styles.embark__hangersIcon} fill="var(--color-secondary-gray-900)" />
							</div>
						)}
					</div>
					<div className={styles.steps}>
						<HowItWorksCard
							description={t('Choose your gender and style preferences to personalize your experience.')}
							step={1}
							title={t('Set your preferences')}
						/>
						<HowItWorksCard
							description={t('Select and upload your essential clothing items, including partner brand pieces.')}
							step={2}
							title={t('Add your wardrobe')}
						/>
						<HowItWorksCard
							description={t(
								'Watch as Lookify organizes your wardrobe digitally, removing backgrounds for a clean look.',
							)}
							step={3}
							title={t('Organize seamlessly')}
						/>
						<HowItWorksCard
							description={t(
								'See how your clothing pieces come together to create stylish looks, hand-picked just for you.',
							)}
							outline
							step={4}
							title={t('Explore outfit ideas')}
						/>
					</div>
					{width <= 1024 && (
						<div className={styles.embarkStylesWrapper}>
							<img
								alt="features"
								className={styles.embarkImageWrapper}
								src="/assets/images/how_it_works_section.jpeg"
							/>
							<h3 className={styles.embarkAdventureTitle}>
								{t('Start on your')} <span className={styles.embarkAdventureTitle_redHighlight}>{t('style')}</span>{' '}
								<span className={styles.embarkAdventureTitle_fleshHighlight}>{t('adventure')}</span>
							</h3>

							<HangersPrototypeIcon className={styles.embark__hangersIcon} fill="var(--color-secondary-gray-900)" />
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default HowItWorks;
