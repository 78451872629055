import { useTranslation } from '@hooks';
import styles from './FrequentlyAskedQuestions.module.css';
import { Accordion, DoodlesIcon } from '@ui-kit';

const FrequentlyAskedQuestions = () => {
	const { t } = useTranslation();

	return (
		<section className={styles.container}>
			<div className={styles.titleBlock}>
				<div className={styles.titleContainer}>
					<h2 className={styles.title}>
						{t('Frequently')} <span className={styles.askedTitle}>{t('asked')}</span>{' '}
						<span className={styles.questionsTitle}>{t('questions')}</span>
					</h2>
					<DoodlesIcon className={styles.doodleTitleIcon} />
				</div>
			</div>

			<div className={styles.accordionWrapper}>
				<Accordion title={t('Do I have to take photos of all my clothes to digitize them?')}>
					<p className={styles.accordionChild}>
						{t(
							"No, you don't! When you join Lookify, we provide a selection of common wardrobe items that you can add with just one tap. For other unique pieces, you can simply take a photo, and Lookify will handle the rest.",
						)}
					</p>
				</Accordion>
				<Accordion title={t('Does Lookify use AI to make outfit recommendations?')}>
					<p className={styles.accordionChild}>
						{t(
							'Yes, Lookify uses advanced AI models to suggest outfit combinations. The AI analyzes your wardrobe and helps you find the best pairings based on various factors like style, texture, and color.',
						)}
					</p>
				</Accordion>
				<Accordion title={t('What factors are considered when creating outfits?')}>
					<p className={styles.accordionChild}>
						{t(
							'Our AI is trained on hundreds of thousands of stylish outfits to understand the best combinations. It takes into account the style, texture, and color of your clothes, while also considering current trends, so you can make the most out of your wardrobe.',
						)}
					</p>
				</Accordion>
			</div>
		</section>
	);
};

export default FrequentlyAskedQuestions;
