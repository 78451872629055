import { Page } from '../../components';
import { useTranslation, useNavigate } from '@hooks';
import styles from './NotFoundPage.module.css';
import { Button } from '@ui-kit';

const NotFoundPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Page title={t('Page Not Found')}>
			<div className={styles.container}>
				<div>
					<h1>{t('Oops! Page not found.')}</h1>
					<p>{t(`The page you are looking for does not exist or might have been removed.`)}</p>

					<Button title={t('Go to Homepage')} variant="secondary" onClick={() => navigate('/')}></Button>
				</div>
			</div>
		</Page>
	);
};

export default NotFoundPage;
