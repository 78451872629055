import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IClothing } from '@typings';

export interface IClothingState {
	selectedClothes: IClothing[];
}

const initialState: IClothingState = {
	selectedClothes: [],
};

export const clothingSlice = createSlice({
	name: 'clothingSlice',
	initialState: initialState,
	reducers: {
		/** @deprecated */
		addCategoryItem: (state: IClothingState, { payload: product }: PayloadAction<IClothing>) => {
			state.selectedClothes = [...state.selectedClothes, product];
		},
		/** @deprecated */
		removeCategoryItem: (state: IClothingState, { payload: clothingId }: PayloadAction<string>) => {
			state.selectedClothes = state.selectedClothes.filter((item) => item.id !== clothingId);
		},
		/** @deprecated */
		removeAllCategoryItems: (state: IClothingState) => {
			state.selectedClothes = [];
		},
		setSelectedClothes: (state: IClothingState, { payload: clothes }: PayloadAction<IClothing[]>) => {
			state.selectedClothes = clothes;
		},
	},
});
