import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

export const useNavigation = () => {
	const [activeNav, setActiveNav] = useState('');
	const location = useLocation();
	const navigate = useNavigate();
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	const setDebouncedActiveNav = useCallback(
		debounce((value: string) => {
			setActiveNav(value);
		}, 250),
		[],
	);

	useEffect(() => {
		const handleScroll = (event: any) => {
			setDebouncedActiveNav(event.srcElement?.location.hash);
		};

		addEventListener('scroll', handleScroll);
		return () => {
			removeEventListener('scroll', handleScroll);
			setDebouncedActiveNav.cancel();
		};
	}, [setDebouncedActiveNav]);

	useEffect(() => {
		if (location.hash) {
			setDebouncedActiveNav(location.hash);
		} else {
			setDebouncedActiveNav('');
		}
	}, [location.hash, setDebouncedActiveNav]);

	const handleNavigation = useCallback(
		(hash: string) => {
			setDebouncedActiveNav(hash);
			navigate(`/${hash}`, { replace: true });
			timeoutRef.current = setTimeout(() => {
				setDebouncedActiveNav(hash);
			}, 1000);
		},
		[navigate, setDebouncedActiveNav],
	);

	useEffect(() => {
		return () => {
			if (timeoutRef?.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	return {
		activeNav,
		handleNavigation,
	};
};
