import { noop } from 'lodash';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useState } from 'reinspect';

const TabBarContext = createContext<TTabBarContext>({
	getForceSetSelectedTabName: noop,
	forceSetSelectedTabName: noop,
});

/** To be able to force change tabName in Footer
 *  we provide this context and children <Page /> component can set force tab name
 *  which will be bind to the current page pathname.
 */
export const TabBarContextProvider = ({ children }: PropsWithChildren) => {
	const [tabBarNameSettings, setTabBarNameSettings] = useState<TTabBarNameSettings | undefined>(undefined);
	const contextValue = useMemo<TTabBarContext>(
		() => ({
			getForceSetSelectedTabName: () => {
				if (window.location.pathname === tabBarNameSettings?.pathname) {
					return tabBarNameSettings?.tabBarName;
				}
			},
			forceSetSelectedTabName: (tabBarName: string) =>
				setTabBarNameSettings({
					tabBarName,
					pathname: window.location.pathname,
				}),
		}),
		[tabBarNameSettings],
	);

	return <TabBarContext.Provider value={contextValue}>{children}</TabBarContext.Provider>;
};

/** Force set selected tab name in <Footer /> component */
export const useTabBarContext = () => useContext(TabBarContext);

type TTabBarNameSettings = {
	pathname: string;
	tabBarName: string;
};

type TTabBarContext = {
	getForceSetSelectedTabName: () => string | void;
	forceSetSelectedTabName: (tabName: string) => void;
};
