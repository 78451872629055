import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { useService } from '@services';
import dayjs from 'dayjs';

// In Milliseconds
const TRACKING_MINIMUM_SPENT_DURATION = 1500;

export const useSectionAnalytics = (activeSection: string) => {
	const analytics = useService('AnalyticsService');
	const [session, setSession] = useState<ISession>({
		startTime: dayjs().toISOString(),
		section: activeSection,
		previousSection: undefined,
		endTime: undefined,
		duration: 0,
	});

	const previousSession = usePrevious(session);

	useEffect(() => {
		setSession((prevState) => {
			const endTime = prevState.startTime;
			const now = dayjs().toISOString();

			return {
				startTime: now,
				section: activeSection,
				previousSection: prevState.section,
				endTime,
				duration: dayjs(now).valueOf() - dayjs(endTime).valueOf(),
			};
		});
	}, [activeSection]);

	useEffect(() => {
		if (session.section !== previousSession?.section && session.duration > TRACKING_MINIMUM_SPENT_DURATION) {
			analytics.trackEvent('User_Action', {
				action_type: 'observation',
				from_page: session.previousSection,
				to_page: session.section,
				start_time: session.startTime,
				end_time: session.endTime,
				duration: session.duration,
			});
		}
	}, [session, previousSession]);

	return session;
};

interface ISession {
	startTime: string; // ISO string
	section: string;
	previousSection: string | undefined;
	endTime: string | undefined; // ISO string
	duration: number;
}
