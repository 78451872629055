import { AbstractReduxService } from '../../abstracts/AbstractReduxService';
import type { TReducerActions } from '../../abstracts/AbstractReduxService';
import type { IStorage } from '../../interfaces/Storage.interface';
// Slices:
import { clothingSlice } from './slices/clothingSlice.slice';

/** Implementation of Redux Global Storage. */
export class ReduxService extends AbstractReduxService<TRootState, TAllActions> {
	static inject = ['LocalStorageService'] as const;
	constructor(readonly localStorage: IStorage) {
		const rootReducer = ReduxService.makeRootReducer(localStorage);
		super(rootReducer, {
			whitelist: [],
			// @todo change this when you need it
			broadcastChannelOption: { type: 'localstorage' },
		});
	}

	// Configuration.
	static makeRootReducer = (localStorage: IStorage) =>
		AbstractReduxService.combineReducers({
			clothingSlice: AbstractReduxService.persistReducer(
				{
					key: 'clothingSlice',
					storage: localStorage,
				},
				clothingSlice.reducer,
			),
		});

	// Actions.
	clothingSlice = clothingSlice.actions;

	// Selectors.
}

export type TRootState = ReturnType<ReturnType<typeof ReduxService.makeRootReducer>>;

type TAllActions = TReducerActions<typeof clothingSlice.actions, 'clothingSlice'>;
