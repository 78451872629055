import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styles from './ResponsiveCarousel.module.css';
import { Navigation, Pagination } from 'swiper/modules';
import type { ReactElement } from 'react';
import React, { useState, useRef } from 'react';
import type SwiperCore from 'swiper';
import './ResponsiveCarousel.overwrite.css';
import { RESPONSIVE_CAROUSEL_BREAKPOINTS } from '@constants';
import SwiperButton from '@ui-kit/components/ResponsiveCarousel/SwiperButton';
import type { Swiper as SwiperClass } from 'swiper/types';

const ResponsiveCarousel: React.FC<IResponsiveCarouselProps> = ({ data, renderItem }) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const swiperRef = useRef<SwiperCore | null>(null);

	return (
		<div className={'swiper'}>
			<Swiper
				breakpoints={RESPONSIVE_CAROUSEL_BREAKPOINTS}
				centeredSlides={true}
				loop={true}
				modules={[Navigation, Pagination]}
				slidesPerView={1}
				spaceBetween={32}
				onSlideChange={(swiper: { realIndex: React.SetStateAction<number> }) => setActiveIndex(swiper.realIndex)}
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper;
				}}
			>
				{data.map((item, index) => (
					<SwiperSlide className={styles.responsiveCarousel__card} key={index}>
						{renderItem(item, index, activeIndex)}
					</SwiperSlide>
				))}
			</Swiper>
			<div className={styles.arrowsContainer}>
				<div className={styles.arrowsWrapper}>
					<SwiperButton direction="prev" swiperRef={swiperRef} />
					<SwiperButton direction="next" swiperRef={swiperRef} />
				</div>
			</div>
		</div>
	);
};

export interface ICarouselItem {
	title: string;
	user?: {
		fullName: string;
		avatar: string | null;
	};
}

interface IResponsiveCarouselProps {
	data: ICarouselItem[];
	renderItem: (item: ICarouselItem, index: number, activeIndex: number) => ReactElement;
}

export default ResponsiveCarousel;
