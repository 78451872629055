import styles from '../TabBar.module.css';
import { Button } from '@ui-kit';
import { useTranslation } from '@hooks';
import { navItems } from '../utils';

const LargeTabBar = ({ activeNav, handleNavigation }: ILargeTabBarProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.header__navigation}>
			{navItems(t).map((item, index) => (
				<Button
					className={`${styles.bar__button} ${activeNav === item.hash ? styles.active : ''}`}
					key={index}
					title={item.title}
					titleClassName={styles.bar__title}
					variant="link"
					onClick={() => handleNavigation(item.hash)}
				/>
			))}
		</div>
	);
};

interface ILargeTabBarProps {
	activeNav: string;
	handleNavigation: (hash: string) => void;
}

export default LargeTabBar;
