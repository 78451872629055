import i18next from 'i18next';
import i18NextLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { possessive } from 'i18n-possessive';

import en from '../../translations/en.json';

import type { i18n, ResourceLanguage } from 'i18next';
import type { II18nService, TAppLanguage } from '../interfaces/I18nService.interface';
import dayjs from 'dayjs';

/** Manage the application language and manage passing translations to the app. */
export class I18nService implements II18nService {
	static inject = [] as const;
	constructor() {
		this.i18n = I18nService.init();
	}

	readonly i18n: i18n;

	static init() {
		const resources: Record<TAppLanguage, ResourceLanguage> = {
			en: { translation: en },
		};
		i18next
			.use(i18NextLanguageDetector) // see https://github.com/i18next/i18next-browser-languageDetector
			.use(initReactI18next) // pass the i18n instance to react-i18next.
			.init(
				{
					compatibilityJSON: 'v3', // to use correct plurals for scanner (see: https://github.com/i18next/i18next-scanner/issues/228)
					fallbackLng: ['en'],
					resources,
					react: {
						nsMode: 'default',
					},
					interpolation: {
						escapeValue: false, // react already safes from xss
					},
					nsSeparator: false,
					keySeparator: false,
					debug: false,
					// @see https://github.com/i18next/i18next-browser-languageDetector#detector-options
					detection: {
						order: ['htmlTag', 'localStorage', 'sessionStorage', 'navigator'], // order and from where user language should be detected
					},
				},
				(error: Error) => {
					if (error) console.error(error);
				},
			);

		i18next.services.formatter?.add('possessive', (value, language = 'en', options) => {
			return possessive(value, language);
		});
		i18next.services.formatter?.add('monthAndYear', (value, language = 'en', options) => {
			return dayjs(value).format('MMMM YYYY');
		});

		return i18next;
	}

	changeLanguage(language: TAppLanguage) {
		this.i18n.changeLanguage(language);
	}

	get language() {
		return this.i18n.resolvedLanguage as TAppLanguage;
	}
}
