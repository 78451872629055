import { useCallback, useState } from 'react';
import { useLockBodyScroll } from '@hooks';

export const useMenu = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	useLockBodyScroll(menuOpen);

	const toggleMenu = useCallback(() => {
		setMenuOpen((prev) => !prev);
	}, []);

	return {
		menuOpen,
		setMenuOpen,
		toggleMenu,
	};
};
