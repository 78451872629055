import ClampLines from 'react-multiline-clamp';
import CollapsibleTextButton from './CollapsibleTextButton';
import { forwardRef } from 'react';
import { clsx, noop } from '@utils';
import styles from './CollapsibleText.module.css';
import type { ForwardedRef, PropsWithChildren } from 'react';
import { useState } from '@hooks';

/** Text block which is collapsed to collapsedNumberOfLines and can be expanded via link-button. */
const CollapsibleText = (
	{
		children,
		collapsedTitle,
		expandedTitle,
		collapsedNumberOfLines = 5,
		expandedNumberOfLines = Number.MAX_SAFE_INTEGER,
		textHighlighted,
		toggleHidden = false,
		onCollapse = noop,
		onExpand = noop,
	}: ICollapsibleTextProps,
	ref: ForwardedRef<HTMLSpanElement>,
) => {
	const [isExpand, setIsExpand] = useState(false);
	return (
		<span className={styles.collapsibleText__content} ref={ref}>
			{/* To highlight collapsible text with inline style need to add additional invisible highlighted text */}
			{textHighlighted && (
				<div className={clsx(styles.collapsibleText__highlighted, !isExpand && styles.collapsibleText__collapsed)}>
					{children}
				</div>
			)}
			<ClampLines
				lines={collapsedNumberOfLines}
				maxLines={expandedNumberOfLines}
				showLessElement={({ toggle }) => (
					<CollapsibleTextButton
						title={expandedTitle}
						onToggle={() => {
							toggle();
							setIsExpand(false);
							setTimeout(onCollapse, 100); // to wait until new element height defined.
						}}
					/>
				)}
				showMoreElement={({ toggle }) => (
					<CollapsibleTextButton
						title={collapsedTitle}
						onToggle={() => {
							toggle();
							setIsExpand(true);
							setTimeout(onExpand, 100); // to wait until new element height defined.
						}}
					/>
				)}
				withToggle={!toggleHidden}
			>
				{children}
			</ClampLines>
		</span>
	);
};

export interface ICollapsibleTextProps extends PropsWithChildren {
	/** Title shown when text is collapsed, e.g. "Show more" */
	collapsedTitle: string;
	/** Title shown when text is expanded, e.g. "Show less" */
	expandedTitle: string;
	/** Number of lines which are always showed. When the number of lines for a text content is bigger than the link-button with collapsedTitle is shown. Default 5.  */
	collapsedNumberOfLines?: number;
	/** The maximum number of lines showed after expanding. Not restricted by default. */
	expandedNumberOfLines?: number;
	/** Hides toggle button if 'true', Default 'false'. */
	toggleHidden?: boolean;
	/** Called when the collapse link clicked. */
	onCollapse?: () => void;
	/** Called when the expand link clicked. */
	onExpand?: () => void;
	/** If value is true highlight text in yellow background*/
	textHighlighted?: boolean;
}

export default forwardRef(CollapsibleText);
