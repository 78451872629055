import type { TFunction } from 'i18next';
import type { ICarouselItem } from '@ui-kit/components/ResponsiveCarousel/ResponsiveCarousel';
import Hero from '@ui-modules/home/components/Hero';
import Inspiration from '@ui-modules/home/components/Inspiration';
import Features from '@ui-modules/home/components/Features';
import Reviews from '@ui-modules/home/components/Reviews';
import HowItWorks from '@ui-modules/home/components/HowItWorks';
import JoinUs from '@ui-modules/home/components/JoinUs';
import WhoWeAre from '@ui-modules/home/components/WhoWeAre';
import FrequentlyAskedQuestions from '@ui-modules/home/components/FrequentlyAskedQuestions';

export const HOME_SECTIONS = [
	{ id: '/', Component: Hero },
	{ id: 'who-we-are', Component: WhoWeAre },
	{ id: 'inspiration', Component: Inspiration },
	{ id: 'features', Component: Features },
	{ id: 'reviews', Component: Reviews },
	{ id: 'how-it-works', Component: HowItWorks },
	{ id: 'join-us', Component: JoinUs },
	{ id: 'faq', Component: FrequentlyAskedQuestions },
];

export const testimonials = (t: TFunction): ICarouselItem[] => [
	{
		title: t(
			'As someone who struggles daily with picking outfits and figuring out what fits my style, Lookify sounds like a breath of fresh air. I often feel overwhelmed by shopping and unsure how to make everything in my closet work together. The idea of getting personalized recommendations based on what I already own is something I can’t wait to try!',
		),
		user: {
			fullName: 'Anahit Hovelyan',
			avatar: '/assets/images/testimonials/Anahit.jpg',
		},
	},
	{
		title: t(
			'I’m so excited about Lookify! Having an app that helps me organize my wardrobe and get outfit suggestions feels like the perfect solution to my daily struggles.',
		),
		user: {
			fullName: 'David Arakelyan',
			avatar: '/assets/images/testimonials/David.jpeg',
		},
	},
	{
		title: t(
			'I’ve been looking for a way to save time and make better use of my wardrobe. Lookify seems like it’s going to be the perfect tool for that!',
		),
		user: {
			fullName: 'Sophia Keshishian',
			avatar: '/assets/images/testimonials/Sophia.jpeg',
		},
	},
	{
		title: t(
			'The idea of seeing how new clothes fit into my existing wardrobe is genius. I’m really looking forward to using Lookify and avoiding unnecessary purchases.',
		),
		user: {
			fullName: 'Mariam Grigoryan',
			avatar: '/assets/images/testimonials/Mariam.jpeg',
		},
	},
	{
		title: t(
			'Lookify sounds like it’s going to revolutionize how I approach fashion. I can’t wait to have a personal stylist on my phone!',
		),
		user: {
			fullName: 'John Melkonian',
			avatar: '/assets/images/testimonials/John.jpeg',
		},
	},
	{
		title: t(
			'Lookify seems like it’s going to save me so much money and time by helping me make the most of my wardrobe. I’m eager to try it out!',
		),
		user: {
			fullName: 'Lilit Harutyunyan',
			avatar: '/assets/images/testimonials/Lilit.jpeg',
		},
	},
	{
		title: t(
			'The concept of Lookify is so exciting! I’ve always wanted something that could help me organize my clothes and plan my outfits effortlessly.',
		),
		user: {
			fullName: 'Emma Brown',
			avatar: '/assets/images/testimonials/Emma.jpeg',
		},
	},
	{
		title: t(
			'I love the idea of having an app that combines wardrobe organization with personalized outfit recommendations. Can’t wait to see it in action!',
		),
		user: {
			fullName: 'Armen Petrosyan',
			avatar: '/assets/images/testimonials/Armen.jpeg',
		},
	},
];
