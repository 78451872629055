export const heroBlockSwipeImages = [
	'/assets/images/hero_cards/hero_1.jpg',
	'/assets/images/hero_cards/hero_2.png',
	'/assets/images/hero_cards/hero_3.png',
	'/assets/images/hero_cards/hero_4.png',
	'/assets/images/hero_cards/hero_5.png',
	'/assets/images/hero_cards/hero_6.png',
	'/assets/images/hero_cards/hero_7.png',
	'/assets/images/hero_cards/hero_8.png',
	'/assets/images/hero_cards/hero_9.png',
];
