import 'swiper/css';
import 'swiper/css/effect-cards';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCards, Navigation } from 'swiper/modules';

import styles from './CardSwiper.module.css';
import Image from 'rc-image';

const CardSwiper = ({ cards }: ICardSwiperProps) => {
	return (
		<Swiper
			autoplay={{
				delay: 2500,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			}}
			cardsEffect={{
				slideShadows: false,
				perSlideOffset: 20,
			}}
			className={styles.swiper}
			effect={'cards'}
			grabCursor={true}
			modules={[EffectCards, Autoplay, Navigation]}
			navigation={true}
			slidesPerView={1}
		>
			{cards?.map((card, index) => (
				<SwiperSlide className={styles.hero__card} key={index}>
					<Image alt={`Hero_Grid_${index}`} className={styles.hero__cardImage} placeholder preview={false} src={card} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export interface ICardSwiperProps {
	cards: string[];
}

export default CardSwiper;
