import type { IAxiosService } from '../interfaces/AxiosService.interface';
import { ClothingService } from './ClothingService';
import { MediaService } from './MediaService';

// import { MediaObjectApiService } from './MediaObjectApiService';

/** Service collects methods to perform http-request to backend API. */
export class ApiService {
	static inject = ['AxiosService'] as const;
	constructor(private readonly axios: IAxiosService) {
		this.clothing = new ClothingService(this.axios);
		this.media = new MediaService(this.axios);
	}

	readonly clothing: ClothingService;
	readonly media: MediaService;
}
