import styles from './ReviewCard.module.css';
import clsx from 'clsx';
import type { ICarouselItem } from '@ui-kit/components/ResponsiveCarousel/ResponsiveCarousel';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { CollapsibleText } from '@ui-kit';

const ReviewCard = ({ title, user, className, activeIndex, index }: IReviewCardProps) => {
	const { t } = useTranslation();

	return (
		<div className={clsx(styles.reviewCard, activeIndex === index && styles.reviewCard_active, className)}>
			<CollapsibleText collapsedTitle={t('Read more')} expandedTitle={t('Show less')}>
				<h3 className={clsx(styles.reviewCard__title, activeIndex === index && styles.reviewCard__title_active)}>
					{title}
				</h3>
			</CollapsibleText>

			<div className={styles.reviewCard__userInfoWrapper}>
				{user?.avatar && <img alt={user.avatar} src={user.avatar} style={{ width: 34, height: 34 }} />}
				{user?.fullName && <h4>{user?.fullName}</h4>}
			</div>
		</div>
	);
};

export interface IReviewCardProps extends ICarouselItem {
	className?: string;
	activeIndex: number;
	index: number;
}

export default ReviewCard;
