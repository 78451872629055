import { useTranslation } from '@hooks';
import { clsx } from '@utils';
import styles from './HowItWorksCard.module.css';
import { LookifyIcon } from '@ui-kit';

const HowItWorksCard = ({ step, title, description, outline = false }: IHowItWorksCardProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			{outline ? (
				<div className={styles.circle__first}>
					<LookifyIcon className={styles.outlined__icon} fill="var(--color-accent)" />
					{/*<p className={styles.outlined__step}>{step}</p>*/}
				</div>
			) : (
				<p className={styles.step}>{step}</p>
			)}
			<div className={styles.column}>
				<p className={styles.title}>{title}</p>
				<p className={styles.description}>{description}</p>
			</div>
		</div>
	);
};

export interface IHowItWorksCardProps {
	step: number;
	title: string;
	description: string;
	outline?: boolean;
}

export default HowItWorksCard;
