import { useTranslation } from '@hooks';
import styles from './WhoWeAre.module.css';
import { DoodlesIcon } from '@ui-kit';

const WhoWeAre = () => {
	const { t } = useTranslation();

	return (
		<section className={styles.container}>
			<div className={styles.image__wrapper}>
				<video autoPlay className={styles.video} controls={false} loop muted playsInline preload="auto">
					<source src="/assets/videos/lookify_animation.webm" type="video/webm" />
					{/* Optional additional formats */}
					<source src="/assets/videos/lookify_animation.mp4" type="video/mp4" />
					{/* Fallback message */}
					Your browser does not support the video tag.
				</video>
			</div>
			<div className={styles.info_wrapper}>
				<div className={styles.title__wrapper}>
					<h2 className={styles.title}>
						{t('Who')} <span className={styles.title__firstHighlight}>{t('we')}</span>{' '}
						<span className={styles.title__secondHighlight}>{t('are')}</span>
					</h2>
					<DoodlesIcon className={styles.title__doodle} />
				</div>
				<p>
					{t(
						'Lookify transforms your fashion experience with AI-driven recommendations, offering endless outfit possibilities for any occasion. Organize your wardrobe and get personalized suggestions based on what you already own. When shopping, we help you find new pieces that fit seamlessly with your existing clothes, making every purchase smarter. Your personal stylist, wardrobe organizer, and shopping guide—all in one.',
					)}
				</p>
			</div>
		</section>
	);
};

export default WhoWeAre;
