import { useScreenSize, useTranslation } from '@hooks';
import styles from './Hero.module.css';
import { Button, CardSwiper } from '@ui-kit';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import Image from 'rc-image';
import { heroBlockSwipeImages } from '@ui-modules/home/components/Hero/utils';

const Hero = () => {
	const { t } = useTranslation();
	const screenSize = useScreenSize();
	const navigate = useNavigate();

	const onGetEarlyAccessClick = () => {
		const targetElement = document.querySelector('#join-us');
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
		}
		navigate(ROUTES.joinUs());
	};

	// @TODO UNHIDE V2.0.0
	// const onDemoClick = () => {
	// 	navigate(ROUTES.demo());
	// };

	const cardSwiper = (
		<div className={styles.hero__image}>
			<CardSwiper cards={heroBlockSwipeImages} />
		</div>
	);

	return (
		<section className={styles.hero}>
			<div className={styles.hero__content}>
				<h1 className={styles.hero__title}>
					{t('Your AI-powered ')} <span className={styles['hero__title_highlight']}>{t('wardrobe assistant')} </span>
					{t('your best looks every day')}
					<span className={styles['hero__subtitle_highlight']}> {t('find, match, and shine')}</span>
				</h1>
				{screenSize.width <= 1024 && cardSwiper}
				<p className={styles.hero__description}>
					{t(
						'Simplify your style routine with AI-powered outfit recommendations. Organize your wardrobe, pair new purchases with confidence, and enjoy the satisfaction of perfectly matched looks for any occasion.',
					)}
				</p>
				{screenSize.width > 1024 && (
					<div className={styles.hero__actions}>
						{/*@TODO UNHIDE V2.0.0*/}
						{/*<Button*/}
						{/*	block={'inline'}*/}
						{/*	icon={<MagicIcon fill={'#141B24'} />}*/}
						{/*	size="large"*/}
						{/*	title={t('Discover the Magic')}*/}
						{/*	onClick={onDemoClick}*/}
						{/*/>*/}
						<Button
							block={'inline'}
							size="large"
							title={t('Join Now!')}
							variant={'secondary'}
							onClick={onGetEarlyAccessClick}
						/>
					</div>
				)}
			</div>
			{screenSize.width > 1024 && (
				<Image
					alt="Hero Grid"
					className={styles.hero__image}
					placeholder
					preview={false}
					src={'/assets/images/hero_grid.webp'}
				/>
			)}
			{screenSize.width <= 1024 && (
				<div className={styles.hero__actions}>
					{/*@TODO UNHIDE V2.0.0*/}
					{/*<Button*/}
					{/*	block={'fit'}*/}
					{/*	icon={<MagicIcon fill={'#141B24'} />}*/}
					{/*	size="large"*/}
					{/*	title={t('Discover the Magic')}*/}
					{/*	onClick={onDemoClick}*/}
					{/*/>*/}
					<Button
						block={'fit'}
						size="large"
						title={t('Join Now!')}
						variant={'secondary'}
						onClick={onGetEarlyAccessClick}
					/>
				</div>
			)}
		</section>
	);
};

export default Hero;
