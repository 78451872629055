import type { EmailJSResponseStatus } from '@emailjs/browser';
import emailjs from '@emailjs/browser';

import type { IAppConfig } from '../interfaces/AppConfig.interface';

import type { TJoinUsForm } from '@ui-modules/home/components/JoinUs/JoinUsForm';
import type { TGetEarlySignedUserDTO } from '@typings';

import { api } from '@convex/_generated/api';
import type { ConvexClientService } from './ConvexClientService';

/** Service collects methods to perform http-request to backend API. */
export class EmailService {
	static inject = ['AppConfigService', 'ConvexClientService'] as const;

	constructor(private readonly appConfig: IAppConfig, private readonly convexClientService: ConvexClientService) {
		const publicKey = this.appConfig.VITE_EMAIL_PUBLIC_KEY;

		if (!publicKey) {
			throw new Error('EmailJS public key is not provided.');
		}

		emailjs.init({ publicKey });
	}

	/**
	 * Checks if the user exists.
	 * @param email - Email to check.
	 * @returns True if the user exists, otherwise false.
	 */
	private async userExists(email: string): Promise<boolean> {
		try {
			const existingUser: unknown[] = await this.convexClientService.query(api.users.getUsers, { email });
			return existingUser?.length > 0;
		} catch (error: any) {
			throw new Error(`Error checking user existence: ${error.message}`);
		}
	}

	/**
	 * Adds a new user to the backend.
	 * @param params - User details to add.
	 */
	private async addUser(params: TGetEarlySignedUserDTO): Promise<void> {
		try {
			await this.convexClientService.mutation(api.users.patch, params);
		} catch (error: any) {
			throw new Error(`Error adding user: ${error.message}`);
		}
	}

	/**
	 * Sends an email and checks or adds the user in the backend.
	 * @param values - The form values for signing up.
	 * @returns EmailJSResponseStatus or throws an error.
	 */
	public async signInForEarlyAccess(values: TJoinUsForm): Promise<EmailJSResponseStatus | undefined> {
		const { email, name } = values;

		const emailLower = email.toLowerCase();
		const serviceID = this.appConfig.VITE_EMAIL_SERVICE_ID;
		const templateID = this.appConfig.VITE_EMAIL_TEMPLATE_ID;

		if (!serviceID || !templateID) {
			throw new Error('EmailJS service ID or template ID is not configured.');
		}

		const templateParams = {
			to_name: name,
			to_email: emailLower,
			message: `${name} successfully signed up for early access.`,
		};

		try {
			// Step 1: Check if the user already exists
			const exists = await this.userExists(emailLower);
			if (exists) {
				throw new Error(`User with email: ${emailLower}, already exists`);
			}

			// Step 2: Add the user to the backend

			// Step 3: Send email using EmailJS
			const result = await emailjs.send(serviceID, templateID, templateParams);
			await this.addUser({ email: emailLower });
			return result;
		} catch (error: any) {
			throw new Error(`${error.message || error.text}`);
		}
	}
}
