import mixpanel from 'mixpanel-browser';
import { camelCase, kebabCase } from '@utils';

import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import upperFirst from 'lodash/upperFirst';

import type { OverridedMixpanel } from 'mixpanel-browser';
import type {
	IMixPanelAnalyticsService,
	TMixPanelAnalyticsEvents,
	TMixPanelGenericAnalyticsEvents,
} from '../interfaces/AnalyticsService.MixPanel.interface';
import type { IEnvironmentConfig, IMixPanelConfig } from '../interfaces/AppConfig.interface';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import type { IBugTracker } from '../interfaces/BugTracker.interface';
import type { ReduxService } from './ReduxService';
import dayjs from 'dayjs';

export class MixPanelService implements IMixPanelAnalyticsService {
	static inject = ['AppConfigService', 'logger', 'SentryService', 'ReduxService'] as const;
	constructor(
		mixPanelConfig: IMixPanelConfig & IEnvironmentConfig,
		logger: IDevLogger,
		private readonly sentry: IBugTracker,
		private readonly redux: ReduxService,
	) {
		this.logger = logger.child('MixPanelService');
		this.mixPanel = mixpanel;
		this.mixPanel.init(mixPanelConfig.MIX_PANEL_TOKEN);
		if (mixPanelConfig.APP_ENV === 'dev') {
			this.logger.debug(`is not initialized because of development environment`);
			this.isEnabled = true;
		} else {
			this.isEnabled = true;
		}
	}

	private logger: IDevLogger;
	private mixPanel: OverridedMixpanel;
	private isEnabled: boolean;
	private distinctId: string | undefined;
	private readonly deviceInfo = {
		appVersion: import.meta.env.PACKAGE_VERSION,
	};
	private readonly dateInfo = {
		timestamp: dayjs().utc().format(),
	};

	// @todo implement when we will have users
	// public setUser(user: TAnalyticsPartialUser): void {
	// 	this.logger.debug('set user', user.id);
	// 	if (!this.isEnabled) return;
	//
	// 	try {
	// 		this.distinctId = user.id;
	// 		this.mixPanel.identify(user.id);
	// 		this.mixPanel.people.set({
	// 			$email: user.email,
	// 			$first_name: user.firstName,
	// 			$last_name: user.lastName,
	// 			$avatar: user.avatar?.contentUrl,
	// 			$distinct_id: user.id,
	// 			distinctId: user.id,
	// 			$timezone: dayjs.tz.guess(),
	// 		});
	// 		this.logger.debug('user was set', user.id, user.firstName, user.lastName);
	// 	} catch (error) {
	// 		this.logger.error(error);
	// 		this.sentry.captureException(error as Error);
	// 		throw error;
	// 	}
	// }

	// public resetUser(): void {
	// 	this.logger.debug('reset user');
	// 	if (!this.isEnabled) return;
	//
	// 	try {
	// 		this.mixPanel.reset();
	// 		this.distinctId = undefined;
	// 	} catch (error) {
	// 		this.logger.error(error);
	// 		this.sentry.captureException(error as Error);
	// 		throw error;
	// 	}
	// }

	public trackEvent<TEventName extends keyof TMixPanelAnalyticsEvents>(
		eventName: TEventName,
		props: TMixPanelAnalyticsEvents[TEventName],
	): void {
		if (!this.isEnabled) return;

		try {
			const eventProps = {
				...this.deviceInfo,
				...this.dateInfo,
				...props,
			};
			this.mixPanel.track(eventName, eventProps);
			this.logger.debug('track event', eventName, eventProps);
		} catch (error) {
			this.logger.error(error);
			this.sentry.captureException(error as Error);
			throw error;
		}
	}

	public trackGenericEvent<TEventName extends keyof TMixPanelGenericAnalyticsEvents>(
		eventName: TEventName,
		props: TMixPanelGenericAnalyticsEvents[TEventName]['props'],
		vars: TMixPanelGenericAnalyticsEvents[TEventName]['vars'],
	): void {
		if (!this.isEnabled) return;

		try {
			const interpolatedEventProps = {
				...this.deviceInfo,
				...this.dateInfo,
				...this.interpolateObjectWithVariables(props, vars ?? {}, kebabCase),
			};
			const interpolatedEventName = this.interpolateStringWithVariables(eventName, vars || {}, this.pascalCase);
			this.mixPanel.track(interpolatedEventName, interpolatedEventProps);
			this.logger.debug('track generic event', interpolatedEventName, interpolatedEventProps);
		} catch (error) {
			this.logger.error(error);
			this.sentry.captureException(error as Error);
			throw error;
		}
	}

	private interpolateObjectWithVariables<T extends Record<string, unknown>>(
		object: T,
		variables: Record<string, string>,
		modify = (value: string) => value,
	): T {
		const interpolateString = (maybeString: unknown) => {
			if (typeof maybeString === 'string') {
				return this.interpolateStringWithVariables(maybeString, variables, modify);
			} else {
				return maybeString;
			}
		};
		const objectWithMappedKeys = mapKeys(object, (value, key) => interpolateString(key));
		const objectWithMappedKeysAndValues = mapValues(
			objectWithMappedKeys,
			(value) => interpolateString(value) as T[keyof T],
		);
		return objectWithMappedKeysAndValues as T;
	}

	private interpolateStringWithVariables(
		template: string,
		variables: Record<string, string>,
		modify = (value: string) => value,
	): string {
		return template.replace(/\{\{(.*?)\}\}/g, (match, variable) => {
			const trimmedVariable = variable.trim();
			if (Object.prototype.hasOwnProperty.call(variables, trimmedVariable)) {
				return modify(variables[trimmedVariable]);
			} else {
				return match;
			}
		});
	}

	private pascalCase(value: string): string {
		return upperFirst(camelCase(value));
	}
}
