import { ServicesProvider } from '@services';
import { ReduxProvider } from './components/ReduxProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer, Flip } from 'react-toastify';
import { ConvexProvider } from 'convex/react';
import type { ReactNode } from 'react';
import type { ServicesContainer } from '../services/setup/ServicesContainer';

// styles
import 'react-loading-skeleton/dist/skeleton.css';

function AppContextsProvider({ servicesContainer, children }: IAppContextsProviderProps) {
	const queryClient = servicesContainer.resolve('ReactQueryService').queryClient;
	const i18n = servicesContainer.resolve('I18nService').i18n;
	const convexClient = servicesContainer.resolve('ConvexClientService').convexClient;

	return (
		<ConvexProvider client={convexClient}>
			<ServicesProvider value={servicesContainer}>
				<QueryClientProvider client={queryClient}>
					<I18nextProvider i18n={i18n}>
						<ReduxProvider>
							<>
								{children}
								<ToastContainer
									autoClose={5000}
									closeButton={false}
									hideProgressBar
									limit={3}
									newestOnTop
									position="top-right"
									transition={Flip}
								/>
							</>
						</ReduxProvider>
					</I18nextProvider>
				</QueryClientProvider>
			</ServicesProvider>
		</ConvexProvider>
	);
}

interface IAppContextsProviderProps {
	servicesContainer: ServicesContainer;
	children: ReactNode;
}

export default AppContextsProvider;
