import { appRouter } from '../../app/appRouter';
import type { IRouterService } from '../interfaces/RouterService.interface';
import type { Router } from '@remix-run/router';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import { ROUTES } from '@constants';
import type { IMixPanelAnalyticsService } from '../interfaces/AnalyticsService.MixPanel.interface';
import dayjs from 'dayjs';

export class RouterService implements IRouterService {
	static inject = ['AnalyticsService', 'logger'];
	private previousRoute: string | null = null;

	constructor(
		private readonly analytics: IMixPanelAnalyticsService,
		logger: IDevLogger,
		private readonly router: Router = appRouter,
	) {
		if (!logger) {
			throw new Error('LoggerService is not initialized');
		}
		this.logger = logger.child('RouterService');
		this.navigate = this.router.navigate;

		// Initialize the previousRoute with the current route when the service is created
		this.previousRoute = this.router?.state?.location?.hash || this.router?.state?.location?.pathname || '';

		this.trackRouteChanged(this.router.state);
		this.router.subscribe((routeState) => this.trackRouteChanged(routeState));

		const utmParams = this.getUTMParameters();

		this.analytics.trackEvent('Session_Started', {
			entry_source: utmParams.referrer,
			entry_time: dayjs().toISOString(),
			landing_page: `${this.origin}${this.previousRoute}`,
		});
	}

	private logger: IDevLogger;
	navigate: Router['navigate'];

	private getUTMParameters() {
		const urlParams = new URLSearchParams(window.location.search);
		return {
			utm_source: urlParams.get('utm_source') || 'direct',
			utm_medium: urlParams.get('utm_medium') || 'none',
			utm_campaign: urlParams.get('utm_campaign') || 'none',
			referrer: document.referrer || 'unknown',
		};
	}

	private trackRouteChanged(routerState: Router['state']) {
		const currentLocation = routerState.location.pathname;
		const currentHash = routerState.location.hash;
		const previousLocation = this.previousRoute ?? undefined;

		// Log the route change for debugging
		this.logger.debug('trackRouteChanged', {
			from: previousLocation,
			to: currentLocation,
		});

		const isActiveRouteBeingTriggered =
			this.getPageName(previousLocation as string) !== this.getPageName(currentHash || currentLocation);

		// Check if there is a valid previous route to track
		if (previousLocation && (previousLocation !== currentHash || currentLocation) && isActiveRouteBeingTriggered) {
			this.analytics.trackEvent('User_Action', {
				action_type: 'navigation',
				from_page: this.getPageName(previousLocation),
				to_page: this.getPageName(currentHash || currentLocation),
			});
		}

		// Update the previous route to the current one
		this.previousRoute = currentHash || currentLocation;
	}

	// Helper method to get a friendly name for the route
	private getPageName(route: string): string {
		if (!route) return '';
		if (route === '/') return 'home';

		if (route in ROUTES) {
			return (ROUTES[route as keyof typeof ROUTES]() || route).replace('#', '');
		}

		return route.replace('#', ''); // Fallback for unmatched routes
	}

	get origin() {
		return window.location.origin;
	}

	handleUserUnauthorized() {
		// For the third party form we need to show error on page instead of unauthorized user.
		// if (window.location.pathname.startsWith('/some-where')) {
		// 	return;
		// }
		// this.router.navigate(ROUTES.signOut());
	}
}
