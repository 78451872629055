import * as Sentry from '@sentry/react';
import type { Breadcrumb } from '@sentry/react';

import type { ISentryConfig, IEnvironmentConfig } from '../interfaces/AppConfig.interface';
import type { IBugTracker } from '../interfaces/BugTracker.interface';
import type { IDevLogger } from '../interfaces/DevLogger.interface';

/** Sentry integration for using it as bug tracker. */
export class SentryService implements IBugTracker {
	static inject = ['AppConfigService', 'logger'] as const;
	constructor(private readonly config: ISentryConfig & IEnvironmentConfig, logger: IDevLogger) {
		this.logger = logger.child('SentryService');
	}

	private logger: IDevLogger;

	public initialize() {
		Sentry.init({
			// dsn: this.config.SENTRY_DSN,
			// integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
			// // Tracing
			// tracesSampleRate: 1.0,
			// tracePropagationTargets: ['localhost', /^https:\/\/lookify\.app\/api/],
			// profilesSampleRate: 1.0,
			// replaysSessionSampleRate: 0.1,
			// replaysOnErrorSampleRate: 0,
			// environment: this.config.APP_ENV,
			// enabled: this.config.APP_ENV === 'dev',
			// release: import.meta.env.PACKAGE_VERSION, //import.meta.env.PACKAGE_VERSION
		});

		this.logger.debug('Sentry initialized');
	}

	/** Send error to Bug Tracker. */
	captureException(error: Error): void {
		Sentry.captureException(error);
		this.logger.debug(`exception captures`, error);
	}

	/** Highlight user steps in the application. */
	addBreadcrumb(breadcrumb: Breadcrumb): void {
		Sentry.addBreadcrumb(breadcrumb);
	}

	/** Identify who uses the application. @todo implement when we will have user base*/
	// setUser(user: ITrackedUser): void {
	// 	Sentry.setUser(user);
	// 	this.logger.debug(`user was set`, user);
	// }

	// resetUser(): void {
	// 	Sentry.setUser({
	// 		id: 'unknown',
	// 		username: 'unknown',
	// 		email: 'unknown',
	// 	});
	// 	this.logger.debug(`user was reset`);
	// }
}
