import { useConvexQuery } from '@hooks';
import { api } from '@convex/_generated/api';

import type { TConvexQuery, TEarlySignedUser, TEarlySignedUserArguments } from '@typings';

export const useEarlyAccessUsersQuery = () => {
	const data = useConvexQuery<TConvexQuery<TEarlySignedUserArguments, TEarlySignedUser[]>>(api.users.getUsers, {});

	return { data, isLoading: !data };
};
