import { useService } from '@services';
import type { TJoinUsForm } from '@ui-modules/home/components/JoinUs/JoinUsForm';
import { useNotification } from './useNotification';
import { useMutation } from '@tanstack/react-query';
import { startCase } from '@utils';
import { useTranslation } from 'react-i18next';
import { toLower } from 'lodash';

export const useEmailHandler = () => {
	const analytics = useService('AnalyticsService');
	const emailService = useService('EmailService'); // This will handle communication with EmailJS
	const { showError, showSuccess } = useNotification();
	const { t } = useTranslation();

	// Initialize the early access mutation with the provided parameters

	return useMutation<any, { text: string }, TJoinUsForm>(
		['email.signInForEarlyAccess'],
		async (values) => {
			await emailService.signInForEarlyAccess({ ...values, name: startCase(toLower(values.name)) });
		},
		{
			onSuccess: (response, variables) => {
				// Success notification logic
				if (variables?.name) {
					showSuccess({
						title: t('Success'),
						subtitle: t(`{{name}}, you have successfully signed up for early access!`, {
							name: startCase(toLower(variables.name)),
						}),
					});
				} else {
					showSuccess({
						title: t('Success'),
						subtitle: t('You have successfully signed up for early access!'),
					});
				}

				analytics.trackEvent('Early_Access', {
					name: startCase(toLower(variables.name)) || '',
					email: variables.email.toLowerCase(),
				});
			},
			onError: (error: any) => {
				showError({
					title: error.message,
				});
			},
		},
	);
};
