import type { IAxiosService } from '../interfaces/AxiosService.interface';
import type { ICategory, TGender } from '@typings';

/** Service collects methods to perform http-request to backend API. */
export class ClothingService {
	static inject = ['AxiosService', 'AnalyticsService'] as const;
	constructor(private readonly axios: IAxiosService) {}

	public async getCategories(params: { gender: TGender }): Promise<ICategory[]> {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(mockData.filter((item) => item.gender === params?.gender));
			}, 1200);
		});
	}

	public async getCategoryById(params: { id: ICategory['id'] }): Promise<ICategory | undefined> {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(mockData.find((item) => item.id === params.id));
			}, 1252);
		});
	}
}

const mockData: ICategory[] = [
	{
		id: 'uuid-1',
		name: 'Accessories',
		min_count: 5,
		gender: 'feminine',
		items: Array.from({ length: 12 }).map((_, index) => ({
			parent_id: 'uuid-1',
			id: `item-uuid-${index + 1}`,
			name: `${index + 1}`,
			image_url: `https://picsum.photos/id/${index + 1}/252/298`,
		})),
	},
	{
		id: 'uuid-2',
		name: 'Tops',
		min_count: 5,
		gender: 'feminine',
		items: Array.from({ length: 12 }).map((_, index) => ({
			parent_id: 'uuid-2',

			id: `item-uuid-${index + 13}`,
			name: `${index + 13}`,
			image_url: `https://picsum.photos/id/${index + 13}/252/298`,
		})),
	},
	{
		id: 'uuid-3',
		name: 'Bottoms',
		min_count: 5,
		gender: 'feminine',
		items: Array.from({ length: 15 }).map((_, index) => ({
			parent_id: 'uuid-3',
			id: `item-uuid-${index + 16}`,
			name: `${index + 16}`,
			image_url: `https://picsum.photos/id/${index + 16}/252/298`,
		})),
	},
	{
		id: 'uuid-4',
		name: 'Shoes',
		min_count: 5,
		gender: 'feminine',
		items: Array.from({ length: 23 }).map((_, index) => ({
			parent_id: 'uuid-4',

			id: `item-uuid-${index + 40}`,
			name: `${index + 40}`,
			image_url: `https://picsum.photos/id/${index + 40}/252/298`,
		})),
	},
	{
		id: 'uuid-5',
		name: 'Full Outfits',
		min_count: 5,
		gender: 'feminine',
		items: Array.from({ length: 12 }).map((_, index) => ({
			parent_id: 'uuid-5',
			id: `item-uuid-${index + 65}`,
			name: `${index + 65}`,
			image_url: `https://picsum.photos/id/${index + 65}/252/298`,
		})),
	},

	{
		id: 'uuid-6',
		name: 'Accessories',
		min_count: 5,
		gender: 'masculine',
		items: Array.from({ length: 23 }).map((_, index) => ({
			parent_id: 'uuid-6',

			id: `item-uuid-${index + 88}`,

			name: `${index + 88}`,
			image_url: `https://picsum.photos/id/${index + 88}/252/298`,
		})),
	},
	{
		id: 'uuid-7',
		name: 'Tops',
		min_count: 5,
		gender: 'masculine',
		items: Array.from({ length: 18 }).map((_, index) => ({
			parent_id: 'uuid-7',

			id: `item-uuid-${index + 90}`,
			name: `${index + 90}`,
			image_url: `https://picsum.photos/id/${index + 90}/252/298`,
		})),
	},
	{
		id: 'uuid-8',
		name: 'Bottoms',
		min_count: 5,
		gender: 'masculine',
		items: Array.from({ length: 23 }).map((_, index) => ({
			parent_id: 'uuid-8',
			id: `item-uuid-${index + 110}`,
			name: `${index + 110}`,
			image_url: `https://picsum.photos/id/${index + 110}/252/298`,
		})),
	},
	{
		id: 'uuid-9',
		name: 'Shoes',
		min_count: 5,
		gender: 'masculine',
		items: Array.from({ length: 19 }).map((_, index) => ({
			parent_id: 'uuid-9',
			id: `item-uuid-${index + 133}`,
			name: `${index + 133}`,
			image_url: `https://picsum.photos/id/${index + 133}/252/298`,
		})),
	},
];
