import styles from './FeaturesCard.module.css';
import type { ReactNode } from 'react';

const FeaturesCard = ({ icon, title, subtitle }: IInspirationCardProps) => {
	return (
		<div className={styles.features__feature}>
			<div className={styles.features__featureIcon}>{icon}️</div>
			<div className={styles.features__featureInfoWrapper}>
				<h3 className={styles.features__featureTitle}>{title}</h3>
				{subtitle && <p className={styles.features__featureDescription}>{subtitle}</p>}
			</div>
		</div>
	);
};

export interface IInspirationCardProps {
	icon: ReactNode;
	title: string;
	subtitle?: string;
}

export default FeaturesCard;
