import { useTranslation } from '@hooks';
import { FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, MessageIcon, PhoneIcon } from '@ui-kit';
import { PROFILE_FACEBOOK_URL, PROFILE_INSTAGRAM_URL, PROFILE_LINKEDIN_URL } from '@constants';

import logo from '../../assets/lookify-logo-light.svg';
import styles from './Footer.module.css';
import type { ReactNode } from 'react';
import dayjs from 'dayjs';

const FooterCTA = ({ icon, title, description, onClick }: IFooterCtaProps) => {
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div className={styles.ctaWrapper} onClick={onClick}>
			{icon}
			<div>
				<h4>{title}</h4>
				<p>{description}</p>
			</div>
		</div>
	);
};

export interface IFooterCtaProps {
	icon: ReactNode;
	title: string;
	description: string;
	onClick?: () => void;
}

const Footer = () => {
	const { t } = useTranslation();
	const handleEmailClick = () => {
		// @todo add constants for those
		window.location.href = 'mailto:lookify.app@gmail.com';
	};

	const handlePhoneClick = () => {
		window.location.href = 'tel:+393762484174';
	};

	const handleLocationClick = () => {
		window.open('https://maps.app.goo.gl/JRfW6dJAHuoarynH6', '_blank');
	};

	return (
		<footer className={styles.footer}>
			<div className={styles.footer__contentWrapper}>
				{/*@todo change the logo with the new one*/}
				<img alt="logo" height={40} src={logo as string} width={170} />
				<div className={styles.footer__socialIconsWrapper}>
					<a
						className={styles.footer__socialIcon}
						href={PROFILE_LINKEDIN_URL}
						rel="noopener noreferrer"
						target="_blank"
					>
						<LinkedinIcon />
					</a>
					<a
						className={styles.footer__socialIcon}
						href={PROFILE_INSTAGRAM_URL}
						rel="noopener noreferrer"
						target="_blank"
					>
						<InstagramIcon />
					</a>
					<a
						className={styles.footer__socialIcon}
						href={PROFILE_FACEBOOK_URL}
						rel="noopener noreferrer"
						target="_blank"
					>
						<FacebookIcon />
					</a>
				</div>
			</div>
			<div className={styles.footer__ctaWrapper}>
				<FooterCTA
					description={t('lookify.app@gmail.com')}
					icon={<MessageIcon />}
					title={t('Email')}
					onClick={handleEmailClick}
				/>
				<FooterCTA
					description={t('+393762484174')}
					icon={<PhoneIcon />}
					title={t('Get in touch')}
					onClick={handlePhoneClick}
				/>
				<FooterCTA
					description={t('Trento Via della Malpensada')}
					icon={<LocationIcon />}
					title={t('Italy, Trento')}
					onClick={handleLocationClick}
				/>
			</div>
			<div className={styles.footer__rights}>
				<p>
					© {dayjs().format('YYYY')} {t('All rights reserved')}
				</p>
				<p>{t('Created by Lookify LLC')}</p>
			</div>
		</footer>
	);
};

export default Footer;
