export { ROUTES, PUBLIC_AUTH_ROUTES } from './app/routes';

export const APP_TITLE = 'Lookify';
export const APP_DESCRIPTION =
	'Simplify your style routine with AI-powered outfit recommendations. Organize your wardrobe, pair new purchases with confidence, and enjoy the satisfaction of perfectly matched looks for any occasion.';

export const PROFILE_FACEBOOK_URL = 'https://www.facebook.com/profile.php?id=61563905564717';
export const PROFILE_INSTAGRAM_URL = 'https://www.instagram.com/lookify.ai/';
export const PROFILE_LINKEDIN_URL = 'https://www.linkedin.com/company/lookifyapp';

export const RESPONSIVE_CAROUSEL_BREAKPOINTS = {
	576: {
		slidesPerView: 2,
		centeredSlides: true,
		spaceBetween: 12,
	},
	768: {
		slidesPerView: 2,
		centeredSlides: true,
		spaceBetween: 12,
	},
	1024: {
		slidesPerView: 2,
		centeredSlides: true,
	},
	1268: {
		slidesPerView: 2,
		centeredSlides: true,

		spaceBetween: 12,
	},
	1440: {
		centeredSlides: true,

		slidesPerView: 3,
	},
};

export const INITIAL_GENDER = 'feminine';

export const HEADER_HEIGHT = 72;
export const CONTENT_TOP_OFFSET = 24;
