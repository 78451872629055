import { useScreenSize, useTranslation, useNavigate } from '@hooks';
import styles from './JoinUs.module.css';
import { ROUTES } from '@constants';
import { ActivityIndicator, Button, HangersPrototypeIcon, LeftWingIcon, RightWingIcon } from '@ui-kit';

import JoinUsForm from './JoinUsForm';
import { useEarlyAccessUsersQuery } from '@ui-modules/home/hooks/useEarlyAccessUsersQuery';
import { useIsMutating } from '@tanstack/react-query';

const JoinUs = () => {
	const navigate = useNavigate();
	const { data: users, isLoading } = useEarlyAccessUsersQuery();

	const isFormSubmitting = useIsMutating({ mutationKey: ['email.signInForEarlyAccess'] });

	const { width } = useScreenSize();
	const { t } = useTranslation();

	return (
		<section className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.titleWrapper}>
					{<LeftWingIcon className={styles.leftWingIcon} />}
					<h2 className={styles.title}>
						<span className={styles.title_join}>{t('Join')} </span>
						{t('fashion')}
						<span className={styles.title_enthusiasts}> {t('enthusiasts')} </span>
						{t('worldwide')}
					</h2>
					{<RightWingIcon className={styles.rightWingIcon} />}
				</div>
			</div>
			<p className={styles.description}>
				{t(
					'Be part of a growing community that’s redefining style with AI. Enjoy exclusive perks by signing up today!',
				)}
			</p>

			<div className={styles.signInForm}>
				<div className={styles.signInForm__firstSection}>
					{width > 768 && (
						<h2 className={styles.signInNote}>
							<span className={styles.signInNote_red}>{t('Sign up now')}</span>{' '}
							{t('and enjoy 1 month of Premium for free!')}
						</h2>
					)}
					{/*Form*/}
					<JoinUsForm />
					<div className={styles.line} />
					<div className={styles.signInForm_testimonialsWrapper}>
						<div>
							<p className={styles.leadersReview}>{t('Want to see reviews?')}</p>
							<Button
								title={t("See other's impressions")}
								variant={'link'}
								onClick={() => {
									const targetElement = document.querySelector('#reviews');
									if (targetElement) {
										targetElement.scrollIntoView({ behavior: 'smooth' });
									}
									navigate(ROUTES.reviews());
								}}
							/>
						</div>
					</div>
				</div>
				{width <= 768 && (
					<h2 className={styles.signInNote}>
						<span className={styles.signInNote_red}>{t('Sign up now')}</span>{' '}
						{t('and enjoy 1 month of Premium for free!')}
					</h2>
				)}
				<div className={styles.signInForm__currentUsersWrapper}>
					<HangersPrototypeIcon className={styles.topHangersCount} />
					<p className={styles.count}>
						{isLoading || isFormSubmitting ? (
							<ActivityIndicator color="var(--color-white)" size="large" />
						) : (
							(users?.length ?? 0) + 400
						)}
					</p>
					<p className={styles.currentUsers}>{t('Current users')}</p>
					<HangersPrototypeIcon className={styles.bottomHangersCount} />
				</div>
			</div>
		</section>
	);
};

export default JoinUs;
