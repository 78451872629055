import { QueryClient } from '@tanstack/react-query';

import { httpCodes } from '../abstracts/AbstractAxiosService/httpCodes';
import { AxiosError } from 'axios';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import type { IRouterService } from '../interfaces/RouterService.interface';

/** Initializes query client for React-Query environment. Allows to call queryClient instance methods. */
export class ReactQueryService {
	static inject = ['logger', 'RouterService'] as const;
	constructor(logger: IDevLogger, router: IRouterService) {
		this.logger = logger.child('ReactQueryService');

		let isTokenRefetching = false; // deduplication of token refetching requests to avoid multiple requests at the same time causes signing out.
		const queryClient = new QueryClient({
			defaultOptions: {
				queries: {
					refetchOnWindowFocus: false,
					refetchIntervalInBackground: false,
					retry: (failureCount, error) => {
						const isUnauthorizedError =
							error instanceof AxiosError && error?.response?.status === httpCodes.UNAUTHORIZED;
						if (isUnauthorizedError) {
							const isFirstFailure = failureCount === 0;
							if (isFirstFailure) {
								this.logger.debug("refetch ['auth.refreshToken'] & retry query because of Unauthorized error");
								if (!isTokenRefetching) {
									isTokenRefetching = true;
									queryClient.refetchQueries(['auth.refreshToken']).finally(() => {
										isTokenRefetching = false;
									});
								}
								return true;
							} else {
								router.handleUserUnauthorized();
							}
						}

						return false;
					},
					retryDelay: 3000,
					staleTime: 3000,
				},
			},
		});

		this.queryClient = queryClient;
	}

	readonly queryClient: QueryClient;
	readonly logger: IDevLogger;

	/** Config of query keys to keep connection between useQuery and invalidateQueries/refetchQueries/prefetchQuery etc  */
	readonly queryKeys = Object.freeze({
		// getMe: () => ['user.getMe'],
	});

	/* Invalidate cache helpers */
}
